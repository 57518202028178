import dayjs from "dayjs";
import React, { Fragment, useState, useEffect } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import environments from "../../../environments";
import { encodeQueryData } from "../../../helpers/endcodeQuery";
import fetchAxios from "../../../helpers/fetchAxios";
import BestSallerTable from "./bestSallerTable";

const DashboardTransaction = (props) => {
  // const { dataWatingForConfirmation, dataSendingForConfirmation } = props;
  const [dataWatingForConfirmation, setDataWatingForConfirmation] = useState(
    []
  );
  const [dataSendingForConfirmation, setDataSendingForConfirmation] = useState(
    []
  );

  useEffect(() => {
    fetchConfirmationWaiting();
    fetchConfirmationSending();
  }, []);

  const fetchConfirmationWaiting = async () => {
    let paramsConfirmation = {
      orderby: "",
      sort: "desc",
      page: 1,
      per_page: 999,
      status: "menunggu konfirmasi",
      code: "",
    };
    const response = await fetchAxios(
      environments.api.transaction +
        (Object.keys(paramsConfirmation).length > 0
          ? "?" + encodeQueryData(paramsConfirmation)
          : ""),
      "get"
    );
    if (response.status === 200) {
      setDataWatingForConfirmation(response.data?.data?.data);
    }
  };

  const fetchConfirmationSending = async () => {
    let paramsConfirmation = {
      orderby: "",
      sort: "desc",
      page: 1,
      per_page: 999,
      status: "sedang diproses",
      code: "",
    };
    const response = await fetchAxios(
      environments.api.transaction +
        (Object.keys(paramsConfirmation).length > 0
          ? "?" + encodeQueryData(paramsConfirmation)
          : ""),
      "get"
    );
    if (response.status === 200) {
      setDataSendingForConfirmation(response.data?.data?.data);
    }
  };

  return (
    <Fragment>
      <Row className="mt-4">
        {dataWatingForConfirmation.length > 0 ? (
          <Col>
            <h5>Transaksi Menunggu Konfirmasi</h5>
            {dataWatingForConfirmation.map((val) => {
              return (
                <div>
                  <Card>
                    <Card.Body>
                      <Row className="align-items-center">
                        <Col sm={4}>
                          <p>{val.code}</p>
                          <p>
                            {dayjs(val.transaction_date).format("DD MMMM YYYY")}
                          </p>
                          <p>{val.mitra?.name}</p>
                        </Col>
                        <Col sm={5}>
                          <p className="success">
                            Rp. {Number(val.grand_total).toLocaleString("id")}
                          </p>
                          <p className="success">{val.status}</p>
                        </Col>
                        <Col sm={3} className="text-center">
                          <Button
                            variant="success"
                            onClick={() => {
                              window.location.href = `/manajemen-transaksi/detail/${val.id}`;
                            }}
                          >
                            Lihat
                          </Button>{" "}
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </div>
              );
            })}
          </Col>
        ) : (
          <Col>
            <p>Data tidak ditemukan</p>
          </Col>
        )}
        {dataSendingForConfirmation.length > 0 && (
          <Col>
            <h5>Transaksi Menunggu Pengiriman</h5>
            {dataSendingForConfirmation.map((val) => {
              return (
                <div>
                  <Card>
                    <Card.Body>
                      <Row className="align-items-center">
                        <Col sm={4}>
                          <p>{val.code}</p>
                          <p>
                            {dayjs(val.transaction_date).format("DD MMMM YYYY")}
                          </p>
                          <p>{val.mitra?.name}</p>
                        </Col>
                        <Col sm={5}>
                          <p className="success">
                            Rp. {Number(val.grand_total).toLocaleString("id")}
                          </p>
                          <p className="success">{val.status}</p>
                        </Col>
                        <Col sm={3} className="text-center">
                          <Button
                            variant="success"
                            onClick={() => {
                              window.location.href = `/manajemen-transaksi/detail/${val.id}`;
                            }}
                          >
                            Lihat
                          </Button>{" "}
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </div>
              );
            })}
          </Col>
        )}
      </Row>
      {/* <BestSallerTable {...props} /> */}
    </Fragment>
  );
};

export default DashboardTransaction;
